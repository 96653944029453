import React, { useState, useEffect, useCallback } from "react";
import styles from "./payment.module.css";
import { message, Tooltip } from "antd";
import BannerStyle from "./banner.module.css";
import { publicRequest } from "../../requestMethod";
const Payment = ({ setLocation }) => {
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(undefined);

  console.log("selected", selected);

  const handleClick = () => {
    localStorage.setItem(
      "formValue",
      JSON.stringify({
        selected,
      })
    );
    setLocation("form3");
  };
  const handleBack = () => {
    setLocation("form1");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await publicRequest.get("package");
        const partnerSubscription = response.data.data.filter(
          (d) => d.userType === "PARTNER"
        );

        setData(partnerSubscription);
        setSelected(partnerSubscription?.[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <div className={BannerStyle.banner}>
        <div className={BannerStyle.bannerTitle}>WELCOME TO THE PARTNER</div>
        <div className={BannerStyle.bannerTitle}>REGISTRATION FORM</div>
      </div>

      <div className={styles.choose_pkg}>
        <p>Choose a package </p>
      </div>

      <div className={styles.main_con}>
        <div className={styles.txt_pic}>
          <img src="Images/logo.svg" alt="missing"></img>
          <div className={styles.list}>
            <div className={styles.listItem}>
              <li> Guaranteed place on our map </li>
              <Tooltip
                title="Your establishment will be prominently displayed on our map, ensuring visibility to NETME users in your area."
                placement="right"
                className={styles.tooltip}
              >
                <img src="Images/tooltip.svg" alt="" />
              </Tooltip>
            </div>
            <div className={styles.listItem}>
              <li>Preferred representation</li>
              <Tooltip
                title="As a partner, your listing will receive priority placement and enhanced visibility, increasing the chances of attracting potential customers."
                placement="right"
                className={styles.tooltip}
                overlayStyle={{ width: "500px !important" }}
              >
                <img src="Images/tooltip.svg" alt="" />
              </Tooltip>
            </div>
            <div className={styles.listItem}>
              <li>Expand your visibility</li>
              <Tooltip
                title="NETME provides an opportunity for your establishment to reach a broader audience and expand your customer base."
                placement="right"
                className={styles.tooltip}
              >
                <img src="Images/tooltip.svg" alt="" />
              </Tooltip>
            </div>
            <div className={styles.listItem}>
              <li>Stamp card advantage</li>
              <Tooltip
                title="Offer exclusive stamp card rewards to NETME users, encouraging repeat visits and fostering customer loyalty."
                placement="right"
                className={styles.tooltip}
              >
                <img src="Images/tooltip.svg" alt="" />
              </Tooltip>
            </div>
            <div className={styles.listItem}>
              <li>In app advertising</li>
              <Tooltip
                title="Utilize in-app advertising to showcase special promotions, events, or offers to our engaged user community."
                placement="right"
                className={styles.tooltip}
              >
                <img src="Images/tooltip.svg" alt="" />
              </Tooltip>
            </div>

            <div className={styles.listItem}>
              <li>Personalise your profile</li>
              <Tooltip
                title="Customize your establishment's profile with photos, descriptions, and additional details to make it more appealing and informative to potential customers."
                placement="right"
                className={styles.tooltip}
              >
                <img src="Images/tooltip.svg" alt="" />
              </Tooltip>
            </div>
            <div className={styles.listItem}>
              <li>Send push notifications</li>
              <Tooltip
                title="Send targeted push notifications to NETME users, keeping them informed about special offers, events, or updates from your establishment."
                placement="right"
                className={styles.tooltip}
              >
                <img src="Images/tooltip.svg" alt="" />
              </Tooltip>
            </div>
          </div>
        </div>

        <div className={styles.maincards_div}>
          <div className={styles.cards_div}>
            {data?.map((_data) => {
              return (
                <div
                  style={
                    selected?._id === _data._id
                      ? { border: "2px solid #8DC3DF" }
                      : {}
                  }
                  className={styles.card1}
                >
                  <div className={styles.innerCard}>
                    <p style={{ textTransform: "capitalize" }}>
                      {_data.planName}
                    </p>
                    <div className={styles.voucher_text}>
                      <span className={styles.voucher_prices}>
                        {_data.price || 0}*
                      </span>
                      /month
                    </div>
                    <img
                      src={
                        selected?._id === _data._id
                          ? "Images/done2.svg"
                          : "Images/tick.png"
                      }
                      className={styles.tick}
                      alt=""
                    ></img>
                    <img
                      src={
                        selected?._id === _data._id
                          ? "Images/done2.svg"
                          : "Images/tick.png"
                      }
                      className={styles.tick}
                      alt=""
                    ></img>
                    <img
                      src={
                        selected?._id === _data._id
                          ? "Images/done2.svg"
                          : "Images/tick.png"
                      }
                      className={styles.tick}
                      alt=""
                    ></img>
                    <img
                      src={
                        selected?._id === _data._id
                          ? "Images/done2.svg"
                          : "Images/tick.png"
                      }
                      className={styles.tick}
                      alt=""
                    ></img>
                    <img
                      src="Images/dash.png"
                      className={styles.dash}
                      alt=""
                    ></img>
                    <img
                      src="Images/dash.png"
                      className={styles.dash}
                      alt=""
                    ></img>
                    <img
                      src="Images/dash.png"
                      className={styles.dash}
                      alt=""
                    ></img>
                  </div>
                  <button
                    onClick={() => {
                      setSelected(_data);
                    }}
                    className={styles.bttn}
                    style={
                      selected?._id === _data._id
                        ? { border: "2px solid #8DC3DF" }
                        : {}
                    }
                  >
                    {selected?._id === _data._id ? "Selected" : "Select"}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.form2_main}>
        {/*div to provide padding on both sides */}
        <div className={styles.reg_process}>
          <p>
            To continue your registration process please select your payment
            method.
          </p>
        </div>
        {/* <div className={styles.payment_lane}>

							<div className={styles.klarna}>
						<input type="radio" id="klarna" value="klarna" name="payment" />
						<label for="klarna">Klarna</label>
						<img
							src="Images/klarna.png"
							className={styles.icon_pic}
							alt="missing"
						></img>
					</div>

							<div className={styles.amazon_pay}>
						<input
							type="radio"
							id="amazonPay"
							value="amazonPay"
							name="payment"
						/>
						<label for="amazonPay">Amazon pay</label>
						<img
							src="Images/amazonpay.png"
							className={styles.icon_pic}
							alt="missing"
						></img>
					</div>
					<div className={styles.paypal}>
						<input type="radio" id="paypal" value="paypal" name="payment" />
						<label for="paypal">Paypal</label>
						<img
							src="Images/paypal.png"
							className={styles.icon_pic}
							alt="missing"
						></img>
					</div>
						</div> */}
        <div className={styles.grid}>
          <div className={styles.klarna}>
            <input
              onClick={() => setPaymentMethod("stripe")}
              checked={paymentMethod === "stripe"}
              type="radio"
              id="stripe"
              value="stripe"
              name="payment"
            />
            <label for="stripe">Stripe</label>
            <img
              src="Images/stripe.png"
              className={styles.icon_pic}
              alt="missing"
            ></img>
          </div>
          <div className={styles.klarna}>
            <input
              checked={paymentMethod === "paypal"}
              onClick={() => setPaymentMethod("paypal")}
              type="radio"
              id="klarna"
              value="klarna"
              name="payment"
            />
            <label for="klarna">Paypal</label>
            <img
              src="Images/paypal.png"
              className={styles.icon_pic}
              alt="missing"
            ></img>
          </div>
        </div>
        <div className={styles.continue}>
          <button onClick={handleBack}>BACK</button>
          <button onClick={handleClick}>
            Continue with{" "}
            <span style={{ textTransform: "capitalize", marginLeft: "4px" }}>
              {paymentMethod}
            </span>
          </button>
        </div>
      </div>

      <div className={styles.f2_ftrmgn}></div>
    </div>
  );
};

export default Payment;
