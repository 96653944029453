import React, { useState } from "react";
import styles from "./addDetails.module.css";
import { Formik, Form, Field } from "formik";
import { message } from "antd";
import CardComponent from "./CardComponent";

function LegalRepresentative({ setLocation, setLegalRepDetails, legalRepDetails }) {
  const handleSubmit = (values) => {
    console.log('va', values);
    if (!values.firstName) {
      message.error("Please enter first name");
    } else if (!values.lastName) {
      message.error("Please enter last name");
    }
    else if (!/^[A-Za-z]+$/.test(values.lastName)) {
      message.error("LastName name should contain only alphabetic characters");
    } else if (!values.email) {
      message.error("Please enter email");
    } else if (!values.mobile) {
      message.error("Please enter mobile no.");
    }
    else if (!/^\d{7,15}$/.test(values.mobile)) {
      message.error("Phone number should have between 7 and 15 digits");
    } else {
      setLegalRepDetails(values);
      setLocation("adDetails");
    }
  };

  const handleBack = () => {
    setLocation("sendRequest");
  }

  return (
    <div className={styles.container}>
      <div className={styles.form_pic_con}>
        {/* <div className={styles.form_pic}>
          <p id={styles.fpt1}>You want to place ads with us?</p>
          <p id={styles.fpt3}>
            Expand your horizons for your business and place ads in the NETME
            App!
          </p>
        </div> */}
        <img src="Images/adFormOne.svg" alt="" />
      </div>

      <div className={styles.yourBenefits}>
        <p>Your benefits!</p>
      </div>
      <CardComponent />

      <div className={styles.main_form}>
        <div className={styles.sub_form}>
          <Formik
            initialValues={{
              mobile: "",
              email: "",
              firstName: "",
              lastName: "",
              ...legalRepDetails
            }}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className={styles.headingMargin}>
                <div className={styles.headingLeft}>
                  <p className={styles.l_r}>Legal Representative</p>
                  <img src="Images/underline.svg" alt="" />
                </div>
                <div className={styles.headingRight}>
                  <img src="Images/twoByThree.svg" alt="" />
                </div>
              </div>


              <div className={styles.legal}>
                <div className={styles.legal_a}>
                  <label className={styles.label}>
                    Full name<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="firstName"
                    type="text"
                    className={styles.form_field}
                    placeholder="Enter First Name"
                  ></Field>
                  <br></br>
                  <label className={styles.label}>
                    E-mail<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="email"
                    type="email"
                    className={styles.form_field}
                    placeholder="Enter email"
                  ></Field>
                  <br></br>
                </div>

                <div className={styles.legal_b}>
                  <label className={styles.label}>
                    Last name<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="lastName"
                    type="text"
                    className={styles.form_field}
                    placeholder="Enter Last Name"
                  ></Field>
                  <br></br>
                  <label className={styles.label}>
                    Mobile number<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="mobile"
                    type="number"
                    className={styles.form_field}
                    placeholder="Mobile number"
                  ></Field>
                  <br></br>
                </div>
              </div>
              <div className={styles.form_btn}>
                <button type="submit" onClick={handleBack}>
                  Back
                </button>
                <button type="submit" onClick={() => handleSubmit}>
                  NEXT
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default LegalRepresentative;
