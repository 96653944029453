// import React from "react";
// import styles from "./header.module.css";
// import { useLocation, useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

// const Header = () => {
//   const navigate = useNavigate();
//   const handleClick = () => {
//     navigate("/register");
//   };
//   const handleLogoClick = () =>{
//     navigate('/')
//   }

//   const location = useLocation()

//   return (
//     <>

//       <div className={location.pathname==='/'?styles.header:styles.header2}>
//         <div className={styles.logo}>
//             <img src="Images/logo.svg" alt="missing" onClick={handleLogoClick}></img>
//         </div>
//         <div className={styles.menu}>
//           <span className={styles.spanOne}>The App</span>
//           <span className={styles.menuSpan}>Blog</span>
//           <span className={styles.menuSpan}>FAQ</span>
//           <span className={styles.menuSpan}>About</span>
//           <Link to='/register'><span className={`${styles.menuSpan} ${styles.activeMenu}`}>Become Partner</span> </Link>
//           <button className={styles.menuButton} onClick={handleClick}>REGISTER NOW</button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Header;

// import React from "react";
// import styles from "./header.module.css";
// import { useLocation, useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

// const Header = () => {
//   const navigate = useNavigate();
//   const handleClick = () => {
//     navigate("/register");
//   };
//   const handleLogoClick = () =>{
//     navigate('/')
//   }

//   const location = useLocation()

//   return (
//     <>

//       <div className={location.pathname==='/'?styles.header:styles.header2}>
//         <div className={styles.logo}>
//             <img src="Images/logo.svg" alt="missing" onClick={handleLogoClick}></img>
//         </div>
//         <div className={styles.menu}>
//           <span className={styles.spanOne}>The App</span>
//           <span className={styles.menuSpan}>Blog</span>
//           <span className={styles.menuSpan}>FAQ</span>
//           <span className={styles.menuSpan}>About</span>
//           <Link to='/register'><span className={`${styles.menuSpan} ${styles.activeMenu}`}>Become Partner</span> </Link>
//           <button className={styles.menuButton} onClick={handleClick}>REGISTER NOW</button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Header;

import React, { useState } from "react";
import styles from "./header.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState(false); // State to manage mobile menu

  const handleClick = () => {
    navigate("/register");
    closeMenu(); // Close the mobile menu after navigating
  };

  const handleLogoClick = () => {
    navigate("/");
    closeMenu(); // Close the mobile menu after navigating
  };

  const location = useLocation();

  const toggleMenu = () => {
    setMenuActive(!menuActive); // Toggle the menu
  };

  const closeMenu = () => {
    setMenuActive(false); // Close the menu
  };

  const handleClose = () => {
    setMenuActive(false); // Close the menu
  };
  return (
    <div className={styles.container}>
      <div className={`${styles.menuToggle} ${menuActive ? styles.active : ""}`} onClick={toggleMenu}>
        <div className={styles.bar}></div>
        <div className={styles.bar}></div>
        <div className={styles.bar}></div>
      </div>


      <div className={location.pathname === "/" ? styles.header : styles.header2}>
        <div className={styles.logo}>
          <img src="Images/logo.svg" alt="missing" onClick={handleLogoClick} />
        </div>
        <div className={`${styles.menu} ${menuActive ? styles.active : ""}`}>
          <span className={styles.spanOne}>The App</span>
          <span className={styles.menuSpan}>Blog</span>
          <span className={styles.menuSpan}>FAQ</span>
          <span className={styles.menuSpan}>About</span>
          <Link to="/register">
            <span className={styles.menuSpan} id={styles.become} onClick={handleClose}>Become Partner</span>
          </Link>
          <button className={styles.menuButton} onClick={handleClick}>
            REGISTER NOW
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
