import React, { useEffect, useState } from 'react'
import styles from "./addDetails.module.css";

function CardComponent() {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 700px)');

    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };

    // Initial check
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChange);
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  return (
    <div className={styles.benefits}>
      <div className={styles.benefitsRowOne}>
        <div className={styles.benefitCard}>
          <img src="Images/compass.png" alt="" />
          <p>High Range</p>
        </div>
        <div className={styles.benefitCard}>
          <img src="Images/megaphone.png" alt="" />
          <p>Direct advertising</p>
        </div>
        <div className={styles.benefitCard}>
          <img src="Images/insert-picture.png" alt="" />
          <p>Image improvement</p>
        </div>
      </div>
      <div className={styles.benefitsRowTwo}>
        <div className={styles.benefitCardTwo}>
          <img src="Images/social-network.png" alt="" />
          <p>Developed to make {mobile ? <br /> : ""} localities more visible</p>
        </div>
        <div className={styles.benefitCardTwo}>
          <img src="Images/tobacconist-shop.png" alt="" />
          <p>Fast acquistion of {mobile ? <br /> : ""}  new customers</p>
        </div>
      </div>
    </div>
  )
}

export default CardComponent

