import React from "react";
// import styles from "../assets/styles/style.footer.css"
import styles from "./footer.module.css";
import heart from "../../assets/icons/Heart.svg"
import fb from "../../assets/icons/facebook.svg"
import insta from "../../assets/icons/instagram.svg"
import twitter from "../../assets/icons/twitter.svg"

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.footerContainer}>
        <div className={styles.footer_pic}>
          <img src="Images/logo.svg" alt="missing"></img>
        </div>
        <div className={styles.footer_txt}>
          <span>Made with</span>
          <img src={heart} alt=""></img>
          <span className={styles.spanRight}>in Munich</span>
        </div>
        <div className={styles.footer_frame}>
          <img src={insta} alt=""></img>
          <img src={twitter} alt=""></img>
          <img src={fb} alt=""></img>
        </div>
      </div>
      <div className={styles.footer_items}>
        <span>The App</span>
        <span>Blog</span>
        <span>FAQ</span>
        <span>About</span>
        <span>Become Partner</span>
        <span>Ads</span>
        <span>Terms & Conditions</span>
        <span>Privacy Policy</span>
        <span>Legal Disclosure</span>
      </div>
      <p className={styles.copyright}>Copyright © 2022. All Rights Reserved</p>
    </div>
  );
};

export default Footer;
