import React from "react";
import styles from "./addDetails.module.css";

const Thanking = () => {
  return (
    <>
      <div className={styles.form_pic_con}>
        {/* <div className={styles.form4_pic}>
        <img src='Images/done.png' alt='missing'></img>
        <p className={styles.f4_maint}>YOUR REQUEST HAS BEEN SENT</p>
        <p className={styles.f4_maint}>OUT AND WILL BE  CHECKED!!</p>
        <p className={styles.f4_subt1}>Thanks for your request!</p>
        <p className={styles.f4_subt2}>You will soon enjoy the benefits of our ad system.</p>  
        </div> */}
        <img src="Images/adFormThanks.svg" alt="" />
        <img src="Images/formFourDone.gif" alt=""  className={styles.done}/>
      </div>
    </>
  );
};

export default Thanking;
