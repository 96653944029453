import React from 'react'
import styles from "./thanking.module.css";
import BannerStyle from './banner.module.css'
const Thanking = () => {
  return (
    <div className={styles.container}>

      <div className={styles.form_pic_con}>
        <div className={BannerStyle.banner}>
          <div className={BannerStyle.bannerTitle}>Thanks for your interest as a partner!</div>
          <div className={BannerStyle.bannerSubTitle}>
            We will check your registration as soon as possible, once you are verified you will be able to all the benifits of your chosen package!
          </div>
        </div>
        {/* <div className={styles.form4_pic}>
        <img src='Images/done.png' alt='missing'></img>
        <p className={styles.f4_maint}>Thanks for your interest as a partner!</p>
        <p className={styles.f4_subt1}>We will check your registration as soon as possible,
            once you are verified you</p>
        <p className={styles.f4_subt2}>you will be able to get all the benefits of your chosen package!</p>  
        </div> */}
        {/* <img src="Images/formFour.svg" alt="" /> */}
        <img src="Images/formFourDone.gif" alt="" className={styles.done} />
      </div>

    </div>
  )
}

export default Thanking