import React from 'react'
import Footer from './Footer/Footer'
import Routepage from './Routepage'
import Header from './Header/Header'


function Navpages() {
  return (
    <>
     <Header/>
     <Routepage/>
     <Footer/> 
    </>
  )
}

export default Navpages
