import React, { useEffect, useState } from "react";
import styles from "./home.module.css";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();
  const [imageSource, setImageSource] = useState("Images/mobileThree.svg");

  useEffect(() => {
    const timeout = setInterval(() => {
      setImageSource((prevImageSource) =>
        prevImageSource === "Images/mobileThree.svg"
          ? "Images/mobileFour.svg"
          : "Images/mobileThree.svg"
      );
    }, 4000);
    return () => clearInterval(timeout);
  }, []);

  const handlePartnerClick = () => {
    navigate("/register");
  };
  const handleAdsClick = () => {
    navigate("/ads");
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.banner}>
          <div className={styles.bannerLeft}>
            <div className={styles.bannerHeading}>
              <p>BOOST YOUR BUSINESS</p>
              <p>WITH NETME!</p>
            </div>
            <div className={styles.description}>
              <p>Connect with local users and grow your</p>
              <p>customer base</p>
            </div>
            <div className={styles.bannerButton}>
              <button onClick={handlePartnerClick}>Become Partner Now</button>
              <button
                onClick={handleAdsClick}
                className={styles.bannerButtonAds}
              >
                Start with Ads
              </button>
            </div>
          </div>
          <div className={styles.bannerRight}>
            <img src="Images/mobileOne.svg" alt="mobileOne" />
            <img
              src="Images/mobileTwo.svg"
              alt="mobileTwo"
              className={styles.bannerImageRight}
            />
          </div>
        </div>

        <div className={styles.benifits}>
          <div className={styles.benifitsHeading}>
            <p>YOUR BENEFITS FROM NETME</p>
          </div>
          <div className={styles.cardContainer}>
            <div className={styles.card1}>
              <div className={styles.card1Image}>
                <img src="Images/Group1.png" alt="" />
              </div>
              <div className={styles.card1Content}>
                <div
                  className={`${styles.card1ContentHeder}`}
                >
                  <p>Attract new customers</p>
                </div>
                <div className={styles.card1ContentDetails}>
                  <p>Connect with local users who want face-to-face meetings, bringing in fresh customers to your business.</p>
                </div>
              </div>
            </div>
            <div className={styles.card1}>
              <div className={styles.card1Image}>
                <img src="Images/Group2.png" alt="" />
              </div>
              <div className={styles.card1Content}>
                <div className={styles.card1ContentHeder}>
                  <p>Passive marketing</p>
                </div>
                <div className={styles.card1ContentDetails}>
                  <p>Gain ongoing exposure on a social platform, allowing your business to remain visible without constant advertising efforts.</p>
                </div>
              </div>
            </div>
            <div className={styles.card1}>
              <div className={styles.card1Image}>
                <img src="Images/Group3.png" alt="" />
              </div>
              <div className={styles.card1Content}>
                <div className={styles.card1ContentHeder}>
                  <p>Increase foot traffic</p>
                </div>
                <div className={styles.card1ContentDetails}>
                  <p>Engage with NETME users interested in meeting at your location, driving more visitors to your establishment.</p>
                </div>
              </div>
            </div>
            <div className={styles.card1}>
              <div className={styles.card1Image}>
                <img src="Images/Group4.png" alt="" />
              </div>
              <div className={styles.card1Content}>
                <div
                  className={`${styles.card1ContentHeder} ${styles.headerWidthTwo}`}
                >
                  <p>Direct customer interaction</p>
                </div>
                <div className={styles.card1ContentDetails}>
                  <p>Communicate directly with users who want to meet at your place, creating personal connections.</p>
                </div>
              </div>
            </div>
            <div className={styles.card1}>
              <div className={styles.card1Image}>
                <img src="Images/Group5.png" alt="" />
              </div>
              <div className={styles.card1Content}>
                <div
                  className={`${styles.card1ContentHeder} ${styles.headerWidthTwo}`}
                >
                  <p>Generate returning customers</p>
                </div>
                <div className={styles.card1ContentDetails}>
                  <p>Connect your business with local users interested in face-to-face meetings.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.benifits1}>
          <div className={styles.benifitsHeading}>
            <p>YOUR BENEFITS FROM NETME</p>
          </div>
          <div className={`${styles.cardRow} ${styles.cardRowMargin}`}>
            <div className={styles.card}>
              <div className={styles.cardImage}>
                <img src="Images/Group1.png" alt="" />
              </div>
              <div className={styles.cardContent}>
                <div
                  className={`${styles.cardContentHeder} ${styles.headerWidth}`}
                >
                  <p>Attract new customers</p>
                </div>
                <div className={styles.cardContentDetails}>
                  <p>Connect with local users who want face-</p>
                  <p>to-face meetings, bringing in fresh</p>
                  <p>customers to your business.</p>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardImage}>
                <img src="Images/Group2.png" alt="" />
              </div>
              <div className={styles.cardContent}>
                <div className={styles.cardContentHeder}>
                  <p>Passive marketing</p>
                </div>
                <div className={styles.cardContentDetails}>
                  <p>Gain ongoing exposure on a social</p>
                  <p>platform, allowing your business to</p>
                  <p>remain visible without constant</p>
                  <p>advertising efforts.</p>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardImage}>
                <img src="Images/Group3.png" alt="" />
              </div>
              <div className={styles.cardContent}>
                <div className={styles.cardContentHeder}>
                  <p>Increase foot traffic</p>
                </div>
                <div className={styles.cardContentDetails}>
                  <p>Engage with NETME users interested in</p>
                  <p>meeting at your location, driving more</p>
                  <p>visitors to your establishment.</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.cardRow}>
            <div className={styles.card}>
              <div className={styles.cardImage}>
                <img src="Images/Group4.png" alt="" />
              </div>
              <div className={styles.cardContent}>
                <div
                  className={`${styles.cardContentHeder} ${styles.headerWidthTwo}`}
                >
                  <p>Direct customer interaction</p>
                </div>
                <div className={styles.cardContentDetails}>
                  <p>Communicate directly with users who</p>
                  <p>want to meet at your place, creating</p>
                  <p>personal connections.</p>
                  <p style={{ visibility: "hidden" }}>personal connections.</p>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardImage}>
                <img src="Images/Group5.png" alt="" />
              </div>
              <div className={styles.cardContent}>
                <div
                  className={`${styles.cardContentHeder} ${styles.headerWidthTwo}`}
                >
                  <p>Generate returning customers</p>
                </div>
                <div className={styles.cardContentDetails}>
                  <p>Connect your business with local users</p>
                  <p>interested in face-to-face meetings.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.boxContainer}>
          <div className={styles.boxLeft}>
            <div className={styles.boxHeder}>
              <p>Take Off With NETME</p>
            </div>
            <div className={styles.boxContent}>
              <p>& Increase your customer growth</p>
              <img src="Images/Subtract.png" alt="" />
            </div>
            <button onClick={handlePartnerClick}>BECOME PARTNER NOW</button>
          </div>
          <div className={styles.boxRight}>
            <img src="Images/boxRightImg.svg" alt="" />
          </div>
        </div>
        <div className={styles.adsContainer}>
          <div className={styles.mobileAdsBanner}>
            <img src={imageSource} alt="missing" />
          </div>
          <div className={styles.mobileAdsContent}>
            <div className={styles.mobileAdsHeader}>
              <p>Not ready to become a Partner yet?</p>
            </div>
            <div className={styles.mobileAdsDetails}>
              <p>Dive into our Ads program on NETME and connect with local users actively seeking face-to-face meetings, discovering a whole new way to reach potential customers.</p>
            </div>
            <button onClick={handleAdsClick}>START WITH ADS</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
