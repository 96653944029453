import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

const LocationPicker = ({ setUserAddress, setLatitude, setLongitude }) => {
  const [value, setValue] = useState(null);
  const [fontSize, setFontSize] = useState("0.9rem");
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");

    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setFontSize("16px");
      } else {
        setFontSize("0.9rem");
      }
    };

    // Initial check
    handleMediaQueryChange(mediaQuery);

    // Add event listener
    mediaQuery.addListener(handleMediaQueryChange);

    // Cleanup event listener on unmount
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);
  useEffect(() => {
    if (value && value.value && value.value.description) {
      console.log("value", value);
      setUserAddress(value.value.description);
      geocodeByAddress(value.value.description)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("lat", lat, lng);
          setLatitude(lat);
          setLongitude(lng);
        })
        .catch((error) => {
          console.error("Error getting latitude and longitude", error);
        });
    }
  }, [value, setLatitude, setLongitude]);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%", // Adjust the width as needed

      marginLeft: "0px",
    }),
    input: (provided) => ({
      ...provided,
      // Add your custom input styles here
    }),
    suggestionsList: (provided) => ({
      ...provided,
      // Add your custom suggestions list styles here
      backgroundColor: "white",
      marginTop: "4px",
      position: "absolute",
      zIndex: 9999,
    }),
    suggestion: (provided) => ({
      ...provided,
      // Add your custom suggestion item styles here
      cursor: "pointer",
      padding: "10px",
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      minHeight: "10px",
      height: "55px",
      borderRadius: "8px",
      padding: "0px 8px",
      border: "none",
      boxShadow: "none",
      fontFamily: "GalanoGrotesque-Medium",
      fontSize: fontSize,
      backgroundColor: "#f7fcff",
      fontWeight: "500",
      backgroundImage: "url(../../../public/Images/dropdown.svg)",
    }),
  };
  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_MAP_KEY}
        selectProps={{
          value,
          onChange: setValue,
          styles: customStyles,
          placeholder: "Search Address",
        }}
      />
      {/* <div>
        <h2>Test this</h2>
      </div> */}
    </div>
  );
};

export default LocationPicker;
