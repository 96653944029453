import React, { useCallback, useEffect, useState } from "react";
import styles from "../Register/termcondition.module.css";
import { publicRequest, userRequest } from "../../requestMethod";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import BannerStyle from "./banner.module.css";

const TermsCondition = ({ setLocation, data }) => {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [voucher, setVoucher] = useState("");
  const [voucherDiscount, setVoucherDiscount] = useState("");
  const [buttonDisable, setButtonDisabled] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [discountMonths, setDiscountMonths] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure(); // Initialize useDisclosure
  const [selected, setSelected] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const value = JSON.parse(localStorage.getItem("formValue"));
      setSelected(value.selected);
    } catch (error) {}
  }, []);
  const clearVoucher = () => {
    setVoucher("");
    setDiscount(0);
    setDiscountMonths(0);
  };
  const fetchVoucherCode = useCallback(async () => {
    try {
      const response = await publicRequest.get(`/voucher/verify/${voucher}`);
      setVoucherDiscount(response.data.discount);
      console.log("response", response);

      if (response.data.discount === "25% off") {
        setDiscount(selected?.price * 0.75);
      } else if (response.data.discount === "50% off") {
        setDiscount(selected?.price * 0.5);
      } else if (response.data.discount === "75% off") {
        setDiscount(selected?.price * 0.25);
      } else if (response.data.discount === "Buy 1 Get 1 free") {
        setDiscountMonths(selected?.timePeriod + 1);
      } else if (response.data.discount === "1 month free") {
        setDiscountMonths(selected?.timePeriod);
      } else if (response.data.discount === "3 months free") {
        setDiscountMonths(selected?.timePeriod + 3 - 1);
      } else if (response.data.discount === "6 months free") {
        setDiscountMonths(selected?.timePeriod + 6 - 1);
      } else if (response.data.discount === "12 months free") {
        setDiscountMonths(selected?.timePeriod + 12 - 1);
      } else {
        setDiscount(0);
        setDiscountMonths(0);
      }
      message.success("successfully applied discount");
    } catch (error) {
      message.error("Vocher expire");
    }
  }, [voucher]);
  useEffect(() => {
    if (voucher && discount === 0) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      setTypingTimeout(
        setTimeout(() => {
          fetchVoucherCode();
        }, 1000) // Adjust the timeout as needed
      );
    }

    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [voucher, discount]);
  const handleBack = () => {
    setLocation("form2");
  };
  console.log("discountMonths", discountMonths);
  console.log("voucherDiscount", voucherDiscount);
  const handleSubmit = async () => {
    if (!isChecked1 || !isChecked2 || !isChecked3) {
      onOpen();

      return;
    }
    console.log("discountMonths", discountMonths);
    if (isChecked1 && isChecked2 && isChecked3) {
      console.log("Inside register");
      try {
        const response1 = await userRequest.post("users", data);
        const response = await publicRequest.post(`/payment`, {
          purchaseType: "SUBSCRIPTION",
          selected,
          discount: voucherDiscount,
          userId: response1.data.data._id,
          successUrl: new URL("/register?step=4", window.location.href),
          voucher,
        });
        if (response.data.url) {
          window.location.href = response.data.url;
        }

        message.success("Registered successfully");
        // setLocation('form4');
        setButtonDisabled(true);
        console.log(buttonDisable);
        console.log("register succress");
        localStorage.clear("formValue");
        // navigate("/register?step=4");
      } catch (err) {
        setButtonDisabled(false);
        const errorMessage = err.response?.data?.message || "An error occurred";
        message.error(errorMessage);
      }
    }
  };

  const handleCancel = () => {
    navigate("/");
  };

  const handleChange1 = () => {
    setIsChecked1(!isChecked1);
  };
  const handleChange2 = () => {
    setIsChecked2(!isChecked2);
  };
  const handleChange3 = () => {
    setIsChecked3(!isChecked3);
  };

  return (
    <div className={styles.container}>
      <div className={BannerStyle.banner}>
        <div className={BannerStyle.bannerTitle}>ONE STEP AWAY!</div>
      </div>
      <div className={styles.grid}>
        <div className={styles.form2_main}>
          <div className={styles.conditionContainer}>
            <p className={styles.accept}>
              Please accept the following to complete your request:
            </p>
            <div className={styles.checkbox}>
              <label className={styles.f3_label}>
                <input
                  type="checkbox"
                  className={styles.f3_input}
                  checked={isChecked1}
                  onChange={handleChange1}
                />
                <span className={styles.span}>Terms & Conditions</span>
              </label>
              <label className={styles.f3_label}>
                <input
                  type="checkbox"
                  className={styles.f3_input}
                  checked={isChecked2}
                  onChange={handleChange2}
                />
                <span className={styles.span}>Partner Agreement</span>
              </label>
              <label className={styles.f3_label}>
                <input
                  type="checkbox"
                  className={styles.f3_input}
                  checked={isChecked3}
                  onChange={handleChange3}
                />
                <span className={styles.span}>Voucher Terms & Conditions</span>
              </label>
            </div>
          </div>

          {/* <div className={styles.cancel}>
          <button onClick={handleCancel}>Cancel</button>
        </div> */}
        </div>
        <div className={styles.card_box}>
          <div className={styles.contentBox}>
            <div>
              <p>
                <b>Plan : {selected?.planName}</b>
              </p>
              <p>
                <b>Price : </b>
                <b>{discount > 0 ? discount.toFixed(2) : null}</b>
                {discountMonths > 0 && discountMonths !== 2 ? (
                  `${discountMonths} month${discountMonths > 1 ? "s" : ""} free`
                ) : (
                  <span
                    style={{
                      textDecoration: discount > 0 ? "line-through" : "",
                      marginLeft: 5,
                      fontSize: 16,
                    }}
                  >
                    €{selected?.price}
                    {discountMonths === 2 && " + 1 month free"}
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className={styles.cardFooter}>
            <div className={styles.voucher}>
              <p>Got a voucher?</p>
            </div>
            <div className={styles.enter_voucher}>
              <input
                disabled={discount > 0 || discountMonths > 0}
                type="text"
                placeholder="Enter your voucher here"
                value={voucher}
                onChange={(e) => {
                  setVoucher(e.target.value);
                }}
              />
              {/* <div className={styles.continue}>
										<button disabled={discount > 0} onClick={fetchVoucherCode}>
										Submit
										</button>
									</div> */}
              {voucher && (
                <button
                  className={styles.remove_voucher}
                  onClick={clearVoucher}
                  title="Remove Voucher"
                >
                  &times; {/* Cross button */}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.acc_nsubmit}>
        <button onClick={handleBack}>BACK</button>
        <button onClick={() => handleSubmit()} disabled={buttonDisable}>
          ACCEPT & SUBMIT
        </button>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          width="100%"
          borderRadius="18px"
          className={styles.modelContent}
        >
          <ModalHeader style={{ color: "#df5468", textAlign: "center" }}>
            Important!
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <p>Please accept the following to</p>
            <p> complete your request</p>
          </ModalBody>
          <ModalFooter>
            <button
              style={{
                margin: "auto",
                backgroundColor: "#333333",
                color: "#FFFFFF",
                fontFamily: "GalanoGrotesque-Black",
                width: "50%",
                padding: "4%",
                borderRadius: "8px",
              }}
              onClick={onClose}
            >
              Got It
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default TermsCondition;
