import React from "react";
import { Route, Routes } from "react-router-dom";
import Register from "../pages/Register/Register";
import HomePage from "../pages/Home/HomePage";
import SendRequest from "../pages/Ads/SendRequest";


const Routepage = () => {
  return (
    <>
    <Routes>
    <Route path="/" element={<HomePage/>} />
    <Route path="/register" element={<Register />} />
    <Route path="/ads" element={<SendRequest />} />
    
    </Routes>
    </>
  );
};

export default Routepage;
