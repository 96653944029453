import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./register.module.css";
import { message } from "antd";
import Payment from "./Payment";
import TermsCondition from "./TermsCondition";
import Thanking from "./Thanking";
import UploadFile from "../../components/UploadFile";
import LocationPicker from "../../components/Location/LocationPicker";
import Select from "react-select";
import BannerStyle from "./banner.module.css";

const dropdownOptions = [
  { value: "Restaurant", label: "Restaurant" },
  { value: "Bar", label: "Bar" },
  { value: "Coffeeshop", label: "Coffeeshop" },
];

const Register = () => {
  const _location = useLocation();
  const [selectedOption, setSelectedOption] = useState("");
  const locationNameRef = useRef(null);
  const companyTaxNoRef = useRef(null);
  const compMobileRef = useRef(null);
  const compEmailRef = useRef(null);
  const cityRef = useRef(null);
  const fullNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const mobileRef = useRef(null);
  const jobPositionRef = useRef(null);
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      minHeight: "10px",
      height: "58px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      fontSize: "1rem",
      backgroundColor: "#f7fcff",
      fontWeight: "500",
      paddingLeft: "14px",
      backgroundImage: "url(../../../public/Images/dropdown.svg)",

      "@media (max-width: 767px)": {
        fontSize: "16px",
        height: "50px",
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%",
      margin: "0px",
      backgroundColor: "#e5e5e5",
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      height: "55px",
      backgroundColor: "#e5e5e5",
      color: "black",
      cursor: "pointer",
      borderBottom: "1px solid white",
      textAlign: "center",
      fontFamily: "GalanoGrotesque-Medium",
      fontSize: "1.2rem",
      fontWeight: "500",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "@media (max-width: 767px)": {
        fontSize: "4vw",
        height: "12vw",
      },
      ":last-child": {
        borderBottom: "none",
      },
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the default arrow and line
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  const navigate = useNavigate();
  const [location, setLocation] = useState("form1");
  const [companyDetails, setCompanyDetails] = useState({
    legalDetails: {},
    businessDetails: {},
  });
  const [userData, setUserData] = useState({});
  const [fileUploaded, setFileUploaded] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  // const [latitude, setLatitude] = useState(40.73061);
  // const [longitude, setLongitude] = useState(-73.935242);

  const [userAddress, setUserAddress] = useState(null);
  const [address, setAddress] = useState(null);
  console.log("userData", userData);
  console.log("userAddress", userAddress);
  console.log("address", address);

  useEffect(() => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => setAddress(data.address));
  }, [latitude, longitude]);

  if (location === "form2") {
    return <Payment setLocation={setLocation} data={companyDetails} />;
  }

  if (location === "form3") {
    return <TermsCondition setLocation={setLocation} data={companyDetails} />;
  }

  if (
    location === "form4" ||
    new URLSearchParams(_location.search).get("step") === "4"
  ) {
    return (
      <Thanking
        setLocation={setLocation}
        data={companyDetails?.companyDetails}
      />
    );
  }

  const handleSubmit = async (values) => {
    setUserData(values);
    let data = {
      legalDetails: {
        email: values.email,
        fullName: values.fullName,
        lastName: values.lastName,
        jobPosition: values.jobPosition,
        mobile: values.mobile,
      },
      businessDetails: {
        name: values.locationName,
        location: {
          type: "Point",
          coordinates: [latitude, longitude],
        },
        address: userAddress,
        category: selectedOption.value,
        city: address?.city,
        // postCode: address?.postcode ? address?.postcode : values.postCode,
        // houseNumber: address?.house_number
        //   ? address?.house_number
        //   : values.houseNumber,
        taxNumber: values.companyTaxno,
        businessEmail: values.compEmail,
        businessMobile: values.compMobile,
        businessTel: values.phone,
        photos: fileUploaded,
      },
    };
    console.log("data", data);
    setCompanyDetails(data);

    if (values.locationName === "") {
      message.error("Please enter location name");
      console.log("Location name is empty");
      locationNameRef.current.focus();
    } else if (!userAddress) {
      message.error("Please enter address");
      console.log("Address is empty");
    } else if (selectedOption === "") {
      message.error("Please select category");
      console.log("Category is not selected");
    }
    //  else if (values.city === "") {
    //   message.error("Please enter city");
    //   console.log("City is empty");
    //   cityRef.current.focus();
    // }
    else if (values.companyTaxno === "") {
      message.error("Please enter company tax no.");
      console.log("Company tax no. is empty");
      companyTaxNoRef.current.focus();
    } else if (values.compEmail === "") {
      message.error("Please enter business email");
      console.log("Business email is empty");
      compEmailRef.current.focus();
    } else if (values.compMobile === "") {
      message.error("Please enter company mobile no.");
      console.log("Company mobile no. is empty");
      compMobileRef.current.focus();
    } else if (!/^\d{7,15}$/.test(values.compMobile)) {
      message.error("Phone number should have between 7 and 15 digits");
      console.log("Invalid company mobile number");
    } else if (values.fullName === "") {
      message.error("Please enter full name");
      console.log("Full name is empty");
      fullNameRef.current.focus();
    } else if (values.lastName === "") {
      message.error("Please enter lastname");
      console.log("Last name is empty");
      lastNameRef.current.focus();
    } else if (!/^[A-Za-z]+$/.test(values.lastName)) {
      message.error("Last name should contain only alphabetic characters");
      console.log("Invalid last name");
      lastNameRef.current.focus();
    } else if (values.jobPosition === "") {
      message.error("Please enter job position");
      console.log("Job position is empty");
      jobPositionRef.current.focus();
    } else if (values.email === "") {
      message.error("Please enter email");
      console.log("Email is empty");
      emailRef.current.focus();
    } else if (!validateEmail(values.email)) {
      message.error("Please enter a valid email");
      console.log("Invalid email format");
      emailRef.current.focus();
    } else if (values.mobile === "") {
      message.error("Please enter mobile no.");
      console.log("Mobile number is empty");
      mobileRef.current.focus();
    } else if (!/^\d{7,15}$/.test(values.mobile)) {
      message.error("Mobile number should have between 7 and 15 digits");
      console.log("Invalid mobile number");
      mobileRef.current.focus();
    } else if (!fileUploaded.length) {
      message.error("Upload at least one picture");
      console.log("Company mobile no. is empty");
    } else {
      const alreadyEmail = false; // await checkEmail(values.email)
      if (alreadyEmail) {
        message.error("Email already registered");
        console.log("Email is already registered");
      } else {
        // message.error("Success");
        setLocation("form2");
        console.log("Success");
      }
    }

    setLocation("form2");
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className={styles.container}>
      <div className={BannerStyle.banner}>
        <div className={BannerStyle.bannerTitle}>WELCOME TO THE PARTNER</div>
        <div className={BannerStyle.bannerTitle}>REGISTRATION FORM</div>
        <div className={BannerStyle.bannerSubTitle}>
          {" "}
          Please fill in the form below{" "}
        </div>
      </div>
      {/* <div className={styles.form_pic_con}>
        <img src="Images/formOne.svg" alt="" />
      </div> */}

      <div className={styles.main_form}>
        <div className={styles.sub_form}>
          <Formik
            initialValues={{
              locationName: "",
              category: "",
              companyTaxno: "",
              mobile: "",
              compMobile: "",
              address: {},
              // city: "",
              email: "",
              compEmail: "",
              phone: "",
              fullName: "",
              lastName: "",
              jobPosition: "",
              // houseNumber: "",
              // postCode: "",
              ...userData,
            }}
            onSubmit={handleSubmit}
          >
            <Form>
              <p className={styles.loc}>Location</p>
              <div className={styles.underLineBorder}>
                <img src="Images/underline.svg" alt="" />
              </div>

              <div className={styles.location}>
                <div className={styles.location_a}>
                  <label className={styles.label}>
                    What is your business called?<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="locationName"
                    type="text"
                    className={styles.form_field}
                    placeholder="Enter Name"
                    innerRef={locationNameRef}
                  ></Field>

                  <br></br>

                  <label className={styles.label}>
                    Category<sup>*</sup>
                  </label>
                  <br></br>
                  <div style={{ marginTop: "0.5rem", marginBottom: "0.8rem" }}>
                    <Select
                      options={dropdownOptions}
                      styles={customStyles}
                      value={selectedOption}
                      onChange={handleOptionChange}
                      placeholder="Select a category"
                      components={{
                        // Add a custom dropdown indicator with your image
                        IndicatorSeparator: () => null, // Hide the separator
                        DropdownIndicator: () => (
                          <img
                            src="Images/dropdown.svg"
                            alt="Custom Dropdown Indicator"
                            style={{
                              width: "15px",
                              height: "3vw",
                              marginRight: "2.5vw",
                            }} // Adjust the dimensions as needed
                          />
                        ),
                      }}
                    />
                  </div>
                  <label className={styles.label}>
                    Company tax no<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="companyTaxno"
                    type="text"
                    className={styles.form_field}
                    placeholder="Enter company tax no"
                    innerRef={companyTaxNoRef}
                  ></Field>
                  <br></br>
                  <label className={styles.label}>
                    Mobile number<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="compMobile"
                    type="number"
                    className={styles.form_field}
                    placeholder="Enter mobile number"
                    innerRef={mobileRef}
                  ></Field>
                  <br></br>
                </div>

                <div className={styles.location_b}>
                  <div className={styles.addressContainer}>
                    <div className={styles.addressParent}>
                      <label className={styles.label}>
                        Address <sup>*</sup>
                      </label>
                      <br></br>

                      <div className={styles.locationAddress}>
                        <LocationPicker
                          setLatitude={setLatitude}
                          setLongitude={setLongitude}
                          setUserAddress={setUserAddress}
                          userAddress={userAddress}
                        />
                      </div>
                    </div>
                    {/* <div className={styles.addressParent}>
                      <label className={styles.label}>
                        House Number<sup>*</sup>
                      </label>
                      <br></br>
                      <Field
                        name="houseNumber"
                        type="text"
                        className={styles.form_field}
                        placeholder="Enter house number"
                        value={
                          address?.house_number
                            ? address?.house_number
                            : address?.houseNumber
                        }
                      ></Field>
                      <br></br>
                    </div> */}
                  </div>
                  {/* <div className={styles.addressContainer}>
                    <div className={styles.addressParent}>
                      <label className={styles.label}>
                        Post Code<sup>*</sup>
                      </label>
                      <br></br>
                      <Field
                        name="postCode"
                        type="text"
                        className={styles.form_field}
                        placeholder="Enter post code"
                        value={
                          address?.postcode
                            ? address?.postcode
                            : address?.postCode
                        }
                      ></Field>
                    </div>
                    <div className={styles.addressParent}>
                      <label className={styles.label}>
                        City<sup>*</sup>
                      </label>
                      <br></br>
                      <Field
                        name="city"
                        type="text"
                        className={styles.form_field}
                        placeholder="Enter city"
                        innerRef={cityRef}
                        value={address?.city ? address?.city : address?.city}
                      ></Field>
                    </div>
                  </div> */}
                  <label className={styles.label}>
                    E-mail<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="compEmail"
                    type="email"
                    className={styles.form_field}
                    placeholder="Enter email"
                    innerRef={emailRef}
                  ></Field>
                  <br></br>
                  <label className={styles.label}>Phone number</label>
                  <br></br>
                  <Field
                    name="phone"
                    type="number"
                    className={styles.form_field}
                    placeholder="Enter phone number"
                  ></Field>
                  <br></br>
                </div>
              </div>
              <div className={styles.formMargin}>
                <p className={styles.legalRepresentative}>
                  Legal Representative
                </p>
                <div className={styles.underLineBorderTwo}>
                  <img
                    src="Images/underlineTwo.svg"
                    alt=""
                    // style={{ width: "33%" }}
                  />
                </div>
              </div>
              <div className={styles.legal}>
                <div className={styles.legal_a}>
                  <label className={styles.label}>
                    First name<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="fullName"
                    type="text"
                    className={styles.form_field}
                    placeholder="First name"
                    innerRef={fullNameRef}
                  ></Field>
                  <br></br>
                  <label className={styles.label}>
                    E-mail<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="email"
                    type="email"
                    className={styles.form_field}
                    placeholder="Enter email"
                    innerRef={emailRef}
                  ></Field>
                  <br></br>
                </div>

                <div className={styles.legal_b}>
                  <label className={styles.label}>
                    Last name<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="lastName"
                    type="text"
                    className={styles.form_field}
                    placeholder="Last name"
                    innerRef={lastNameRef}
                  ></Field>
                  <br></br>
                  <label className={styles.label}>
                    Mobile number<sup>*</sup>
                  </label>
                  <br></br>
                  <Field
                    name="mobile"
                    type="number"
                    className={styles.form_field}
                    placeholder="Mobile number"
                  ></Field>
                  <br></br>
                </div>
              </div>
              <div className={styles.job_pos}>
                <label className={styles.label}>
                  Job position<sup>*</sup>
                </label>
                <br></br>
                <Field
                  name="jobPosition"
                  type="text"
                  className={styles.form_field}
                  placeholder="Job position"
                  innerRef={jobPositionRef}
                ></Field>
                <br></br>
              </div>
              <div className={styles.formMargin}>
                <p className={styles.im}>Images</p>
                <div className={styles.underLineBorderThree}>
                  <img
                    src="Images/underline.svg"
                    alt=""
                    // style={{ height: "0.24rem" }}
                  />
                </div>
              </div>

              <div className={styles.upload}>
                <UploadFile setFileUploaded={setFileUploaded} />
              </div>
              <div className={styles.form_btn}>
                <div></div>
                <button
                  type="submit"
                  id={location === "form2"}
                  onClick={() => handleSubmit}
                >
                  NEXT
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Register;
